<template>
  <div v-if="examInfo">
    <el-dialog
      :visible.sync="phoneDialog"
      :show-close="false"
      :close-on-click-modal="false"
      width="334px"
    >
      <div class="phone_box">
        <div class="phone_top">考试即将开始</div>
        <div class="phone_title">{{ examInfo.examName }}</div>
        <div class="phone_time">
          考试时间：{{
            examInfo.startTime !== null && examInfo.endTime !== null
              ? examInfo.startTime
              : "不限时"
          }}
        </div>
        <div class="phone_content">
          考试时长：
          <span style="color: #ff1d1d">{{ examInfo.duration }}分钟</span>
        </div>
        <el-button
          size="small"
          class="phone_btn"
          :disabled="!buttonStaus"
          @click="startHandle()"
          :icon="buttonStaus ? 'el-icon-caret-right' : 'el-icon-close'"
          :type="buttonStaus ? 'primary' : 'info'"
        >
          {{ checkExam(examInfo) ? "去考试" : "暂不开放" }}
        </el-button>
        <!-- <el-button
          class="phone_btn"
          v-if="examInfo.status == 1"
          @click="startHandle"
        >
          <span> 开始考试 </span>
        </el-button>
        <el-button round class="phone_btn2" type="info" v-else disabled>
          暂不开放
        </el-button> -->
        <div class="phone_shuaxin">
          点击 <span @click="shuaxin">刷新</span> 进行考试
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      phoneDialog: true,
      examInfo: {
        examName: "",
        startTime: "",
        duration: "",
        status: "",
      },
      total: "",
      queryInfo: {
        examType: null,
        startTime: null,
        endTime: null,
        examName: null,
        pageNo: 0,
        pageSize: 10,
      },
      buttonStaus: false, //考试按钮的状态
    };
  },
  mounted() {
    this.getExamInfo();
  },
  computed: {
    //检查考试的合法性
    checkExam(row) {
      // var row = row.examInfo;
      return (row) => {
        if (!row.status) {
          console.log("--");
          return false;
        }
        console.log("================================");
        console.log(row);
        //status考过试：2 未考：1
        if (row.status === 2) {
          this.buttonStaus = false;
          return false;
        }
        //如果时间都为空 直接可以考试
        if (!row.startTime && !row.endTime) {
          this.buttonStaus = true;
          return true;
        }
        let startTimeStr = row.startTime.replace(/\-/g, "/");
        let endTimeStr = row.endTime.replace(/\-/g, "/");

        let currentTime = new Date();
        let endTime = new Date(endTimeStr);
        let startTime = new Date(startTimeStr);

        //如果开始时间，结束时间都不为空时
        if (row.startTime && row.endTime) {
          if (currentTime - startTime <= 0) {
            this.buttonStaus = false;
            return false;
          }
          if (currentTime - endTime >= 0) {
            this.buttonStaus = false;
            return false;
          }
        }
        //如果开始时间为空
        if (!row.startTime && row.endTime) {
          if (currentTime - endTime >= 0) {
            this.buttonStaus = false;
            return false;
          }
        }

        //如果结束时间为空
        if (!row.startTime && row.endTime) {
          if (currentTime - startTime <= 0) {
            this.buttonStaus = false;
            return false;
          }
        }

        this.buttonStaus = true;
        return true;

        // let durtime =new Date(row.endTime) - new Date(row.startTime)
        // console.log(new Date(row.endTime))
        // console.log(durtime)
        // if (
        //   (row.startTime === "" && row.endTime === "") ||
        //   (row.startTime === null && row.endTime === null)
        // ) {
        //   return true;
        // } else if (row.startTime === "null") {
        //   return date < new Date(row.endTime);
        // } else if (row.endTime === "null") {
        //   return date > new Date(row.startTime);
        // } else if (
        //   date > new Date(row.startTime) &&
        //   date < new Date(row.endTime)
        // )
        // console.log('222')
      };
    },
  },
  methods: {
    shuaxin() {
      this.getExamInfo();
    },
    startHandle() {
      var endTime = this.examInfo.endTime;
      if (endTime) {
        endTime = endTime.replace(/\-/g, "/");
        if (new Date() - new Date(endTime) > 0) {
          this.$message.error("考试时间已过");
          this.getExamInfo();
          return;
        }
      }
      this.phoneDialog = false;
      this.$router.push("/exam/4");
    },
    getExamInfo() {
      this.$http.post(this.API.getExamInfo, this.queryInfo).then((resp) => {
        console.log(resp);
        if (resp.data.code === 200) {
          this.examInfo = resp.data.data[4];
          this.loading = false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/.el-dialog {
  background: url("../assets/imgs/phoneLoding.png") no-repeat center;
  background-size: 100% 100%;
  height: 516px;
  box-shadow: none;
  position: relative;
  top: -80px;
}
.phone_box {
  position: relative;
  top: 100px;
}
.phone_top {
  font-size: 18px;
  color: #ffffff;
  text-align: center;
  font-weight: 500;
}
.phone_shuaxin {
  color: #999999;
  margin: auto;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  margin-top: 10px;
  span {
    display: inline-block;
    background-color: #4284fd;
    color: #ffffff;
    border-radius: 3px;
    width: 45px;
    height: 27px;
    line-height: 27px;
    font-weight: 500;
  }
}
.phone_content {
  margin: auto;
  text-align: center;
  color: #333333;
  font-size: 17px;
  font-weight: 500;
  margin-top: 20px;
}
.phone_btn {
  width: 250px;
  height: 41px;
  border-radius: 20px;
  margin: auto;
  display: block;
  margin-top: 20px;
  font-size: 18px;
  font-weight: 400;
}
.phone_btn2 {
  width: 250px;
  height: 41px;
  line-height: 41px;
  color: #ffffff;
  border-radius: 20px;
  text-align: center;
  margin: auto;
  margin-top: 18px;
  font-size: 18px;
  font-weight: 500;
  padding: 0;
  display: block;
  span {
    font-weight: 400;
  }
}

.phone_time {
  height: 41px;
  width: 280px;
  color: #4284fd;
  font-size: 17px;
  background-color: #e2ecff;
  margin: auto;
  border-radius: 20px;
  text-align: center;
  line-height: 41px;
  font-weight: 500;
  margin-top: 25px;
}
.phone_title {
  color: #333333;
  font-size: 21px;
  text-align: center;
  width: 230px;
  margin: auto;
  margin-top: 37px;
  font-weight: 500;
  line-height: 1.5;
  overflow: hidden;
  height: 63px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>
